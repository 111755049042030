import React from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export function StyledDropzone(props: any) {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = props.state as DropzoneState
    //useDropzone({accept: 'image/*'});

    return (
        <div className="container">
            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                <React.Fragment>
                    {props.children}
                </React.Fragment>

                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the file here ...</p> :
                        <p>Drag 'n' drop file here</p>
                }

            </Container>
        </div>
    );
}

