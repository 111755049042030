import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component, useRef, useEffect, useImperativeHandle, useState } from "react";
import './App.css';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from './MUI';
import Record, { showRaw } from './Record';
import { Grouped, isGrouped, SplunkLogRecord } from './SplunkLogDealer';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import AllOutIcon from '@material-ui/icons/AddBoxRounded';
import AllInIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import Chart from "react-google-charts";
import { trimEllip } from './util';

type RecordGroupType = {
    data: Grouped,
    expanded?: boolean,
    controller: any,
    ref: any

}

function getGroupColor(grouped: Grouped): string {
    let color = ""
    if (grouped.hasError && grouped.hasWarning) {
        color = "rgba(220, 118, 51 , 0.9)"
    } else if (grouped.hasWarning) {
        color = "rgba(224, 145, 9, 0.9)"
    } else if (grouped.hasError) {
        color = "rgba(231, 76, 60, 0.9)"
    } else
        if (grouped.value.includes("N/A")) {
            color = "rgba(0, 0, 0, .35)"
        }
    return color
}


function generateTimelineData(grouped: Grouped): any[] {
    const columns = [
        { type: "string", id: grouped.key },
        { type: 'string', id: 'Name' },
        { type: "datetime", id: "Start" },
        { type: "datetime", id: "End" }
    ];

    const data: any[] = [columns]

    grouped.children.forEach((e) => {
        if (isGrouped(e)) {
            const g = (e as Grouped);
            //console.log(g.value, g.earliestDate, g.latiestDate)
            data.push(
                [
                    g.value, `(${g.totalNum}) records`, g.earliestDate, g.latiestDate
                ]
            )
        }
    })

    return data
}

function formatDuration(ms: number): string {
    return new Date(ms).toISOString().substr(11, 12)

}

const RecordGroup: React.FunctionComponent<RecordGroupType> = React.forwardRef((props, ref) => {

    const grouped: Grouped = props.data;

    const [expanded, setExpanded] = useState<boolean>(props.expanded || false);

    const myRef = useRef<any>({});
    const sonGroupRef = useRef<any>({});

    var hasSonGroup = false
    props.data.children.map((item, i) => {
        if (isGrouped(item)) {
            hasSonGroup = true
        }
    })

    const collapseAll = (collapse: boolean, triggerlevel: number = 0) => {
        if (!collapse && !expanded) {
            //a bug here (introduced by lazy showing unexpanded children) , but seems useful, everytime expands, only one more level expand
            setExpanded(true)
        } else if (triggerlevel > 0) {
            setExpanded(!collapse)
        } else {
        }

        const children: any[] = sonGroupRef.current
        //console.log("xxx-" + children)
        if (children != null) {
            for (var prop in children) {
                // skip loop if the property is from prototype
                if (!children.hasOwnProperty(prop)) continue;

                let it: any = children[prop];

                if (it != null) {
                    it.collapseAll(collapse, triggerlevel + 1)
                }
            }


        }
    }


    const jumpTo = (recordNo: number): boolean => {

        const grouped = props.data

        if (grouped.no > recordNo || grouped.endNo < recordNo) {
            return false
        }

        const list = grouped.children

        for (var i = 0; i < list.length; i++) {
            let item = list[i]

            if (isGrouped(item)) {

                const sonGrouped = item as Grouped
                if (sonGrouped.no <= recordNo && sonGrouped.endNo >= recordNo) {

                    const sonGroupHtmlObj = (sonGroupRef.current[i]! as any)

                    if (!sonGroupHtmlObj) {

                        // expand first, after refreshed, then jump
                        setExpanded(true)

                        setTimeout(() => {
                            (sonGroupRef.current[i]! as any).jumpTo(recordNo)
                        }, 100);

                    } else {
                        return sonGroupHtmlObj.jumpTo(recordNo)
                    }
                    return true

                }

            } else {
                if (item.no == recordNo) {
                    const sonObj = myRef.current[i];
                    if (!sonObj) {
                        // expand first, after refreshed, then jump

                        setExpanded(true)

                        setTimeout(() => {
                            jumpToDiv(myRef.current[i] as any)
                        }, 100);
                    } else {
                        jumpToDiv(sonObj)
                    }
                    return true
                }
            }
        }

        return false
    }

    useImperativeHandle(ref, () => ({
        collapseAll: (collapse: boolean, triggerlevel: number = 0) => {
            collapseAll(collapse, triggerlevel)
        },

        jumpTo: (recordNo: number): boolean => {
            return jumpTo(recordNo)
        }
    }));

    //only enable for root
    const timelineData = !grouped.parent ? generateTimelineData(grouped) : null

    let namesSet = new Set();

    if (timelineData) {
        for (var prop in timelineData) {
            // skip loop if the property is from prototype
            if (!timelineData.hasOwnProperty(prop)) continue;
            //onsole.log("XXX", timelineData[prop])
            namesSet.add(timelineData[prop][0])
        }
    }

    const sizeOfNames = namesSet.size;
    const [timelineRowNum, setTimeLineRowNum] = useState<number>(sizeOfNames);

    if (timelineRowNum != sizeOfNames) {
        //TODO bug here, not reflect the heights
        //console.log("XXX", sizeOfNames)
        setTimeLineRowNum(sizeOfNames)
    }

    {

        return (
            <div style={{ paddingLeft: "10px" }}>
                <ExpansionPanel
                    expanded={expanded}
                    onChange={(event: React.ChangeEvent<{}>, expanded: boolean) => {
                        if (event.isDefaultPrevented()) {
                            return
                        }
                        setExpanded(expanded)
                    }
                        // || grouped.children.length < 2
                    }

                    TransitionProps={{
                        timeout: 0
                    }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        {/* root */}
                        {/* TODO consider all collapse all here */}
                        {/* <IconButton><RefreshIcon /></IconButton> */}
                        <div style={{ width: "100%" }}>

                            {/* non root */}
                            {/* gray items seems not normalized  */}
                            <div style={{ width: "100%", color: `${getGroupColor(grouped)}` }}>

                                <div style={{ float: "left", display: "inline-block" }}>
                                    {!grouped.parent &&
                                        <div>
                                            <b>{trimEllip(grouped.value, 50)}</b>&nbsp;<small>{grouped.key}</small>
                                            &nbsp;<b>({`${grouped.totalNum} Records`})</b>&nbsp;
                                            &nbsp;<b>{"Start From: "}</b>{grouped.earliestDate.toISOString()}
                                            &nbsp;<b>{"Duration: "}</b>{(grouped.latiest != grouped.earliest) ? " (" + formatDuration(grouped.duration) + ")" : ""}
                                        </div>

                                    }

                                    {grouped.parent && <div>
                                        <b>{trimEllip(grouped.value, 50)}</b>
                                        &nbsp;<small style={{ verticalAlign: "top" }}>{grouped.key}</small>
                                        &nbsp;<b>({`${grouped.totalNum}`})</b>&nbsp;
                                        &nbsp;{grouped.earliestDate.toISOString().slice(11, -1)}
                                        &nbsp;{(grouped.latiest != grouped.earliest) ? " (" + grouped.duration + " ms)" : ""}
                                    </div>
                                    }
                                </div>

                                <div style={{ float: "right" }}>
                                    {grouped.parent &&
                                        <small>{grouped.highlight.length > 80 ? grouped.highlight.slice(0, 80) + "..." : grouped.highlight}</small>
                                    }

                                    {hasSonGroup && <Tooltip
                                        title={
                                            <React.Fragment>
                                                <big>{`collapse all`}</big>
                                            </React.Fragment>
                                        }
                                    >
                                        <IconButton size="small" onClick={(event: React.MouseEvent<HTMLElement>) => {
                                            collapseAll(true)
                                            event.preventDefault()

                                        }}><AllInIcon fontSize="small" /></IconButton>
                                    </Tooltip>
                                    }

                                    {hasSonGroup && <Tooltip
                                        title={
                                            <React.Fragment>
                                                <big>{`expanse all`}</big>
                                            </React.Fragment>
                                        }
                                    >
                                        <IconButton size="small" onClick={(event: React.MouseEvent<HTMLElement>) => {
                                            collapseAll(false)
                                            event.preventDefault()

                                        }}><AllOutIcon fontSize="small" /></IconButton>
                                    </Tooltip>
                                    }

                                    {grouped.parent && <Tooltip
                                        title={
                                            <React.Fragment>
                                                <big>{`hide this, by filter out ${grouped.key} = ${grouped.value}`}</big>
                                            </React.Fragment>
                                        }
                                    >
                                        <IconButton size="small" onClick={() => {
                                            // props.controller.setFilterExpression(props.controller.filterExpression + " && this." + grouped.key + " !== " + grouped.value)
                                            props.controller.excludeGroup(grouped)

                                        }}><DeleteSharpIcon fontSize="small" /></IconButton>
                                    </Tooltip>
                                    }
                                </div>
                            </div>

                            {
                                timelineData &&
                                <div style={{ height: (timelineRowNum * 45 + 10) + "px" }}
                                >
                                    <br />
                                    <br />
                                    <Chart
                                        chartType="Timeline"
                                        data={timelineData}
                                        width="99%"

                                        height={
                                            "100%"
                                        }
                                        options={{
                                            timeline: {
                                                colorByRowLabel: true,
                                            }
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: 'select',
                                                callback: ({ chartWrapper }) => {
                                                    const chart = chartWrapper.getChart()
                                                    const selection = chart.getSelection()
                                                    if (selection.length === 1) {
                                                        const [selectedItem] = selection
                                                        const dataTable = chartWrapper.getDataTable()
                                                        const { row } = selectedItem

                                                        //console.log(row, dataTable!.getValue(row, 0), dataTable!.getValue(row, 1), dataTable!.getValue(row, 2))

                                                        const theOne = myRef.current[row];
                                                        // quick jump

                                                        jumpToDiv(theOne);
                                                    }

                                                    //console.log(selection)
                                                },
                                            },
                                        ]}

                                    />
                                </div>

                            }


                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        {expanded && <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, maxWidth: "100%" }}>
                            {(grouped.commonAttrsForDisplay.length > 0) &&
                                <div style={{ padding: "5px", paddingLeft: "15px", backgroundColor: "#ddffff" }}>
                                    {showRaw("Shared: " + grouped.commonAttrsForDisplay.map(
                                        (e) => {
                                            if (e.value)
                                                return e.key + "=" + e.value
                                            else
                                                return ""
                                        }
                                    ).join(", "))}
                                </div>}
                            {props.data.children.map((item, i) => {

                                if (isGrouped(item)) {

                                    return (<div ref={ref => myRef.current[i] = ref} key={item.no} style={{ padding: `${(3 - grouped.level) * 2}px` }}>
                                        <RecordGroup key={item.no} data={item as Grouped} expanded={false} controller={props.controller}
                                            ref={(ref: any) => {

                                                sonGroupRef.current[i] = ref
                                            }
                                            }
                                        />
                                    </div>)
                                } else {
                                    return (<div ref={ref => myRef.current[i] = ref} key={item.no} style={
                                        (item as SplunkLogRecord).no % 2 == 0 ?
                                            { backgroundColor: 'rgba(0, 0, 0, .1)', paddingLeft: "10px" } :
                                            { backgroundColor: 'rgba(0, 0, 0, .035)', paddingLeft: "10px" }
                                    }>
                                        <Record key={item.no} data={item as SplunkLogRecord} controller={props.controller} />
                                    </div>)
                                }

                            })

                            }

                        </div>}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

        )
    }
})

export default RecordGroup


function jumpToDiv(theOne: any) {

    //console.log("jumpToDiv")
    theOne.scrollIntoView();

    theOne.style.backgroundColor = "#00AA00";
    setTimeout(() => {
        theOne.style.backgroundColor = "#FFFFFF";
    }, 1000);

    //TODO clearTimeout ?
}

