
export function sharedStart(a: string, b: string) {
    let L = a.length, i = 0;
    while (i < L && a.charAt(i) === b.charAt(i)) i++;
    return a.substring(0, i);
}

export function evalInContext(context: any, js: string) {
    return Function('return ' + js).call(context);
}

export const trimEllip = function (raw: string, length: number): string {
    return raw.length > length ? raw.substring(0, length) + "..." : raw;
  }