import _, { now } from "lodash";
import { evalInContext, sharedStart } from "./util";

import jsonpath from "jsonpath"

export type Customized = {
    //true, means good record, false, means bad, will be excluded
    postHandleRecord: (record: SplunkLogRecord) => boolean,

    defaultFoldingRule?: string[]
    defaultSubFilters: string[]
}

type CommonAttribute = {
    key: string,
    value: string,
    includeWithoutKeyWhenMatch: boolean
}


type ChangeFrequency = {
    lastValue: any;
    changedTime: number;
    changedSignature: Set<number>;
};

type CommonKey = {
    key: string,
    includeRate: number,
    valuesVarientsCount: number,
    valuesRate: Map<string, number>,
    changeFrequency: ChangeFrequency,
    equalWithKey: string,
    notRecommand: boolean,
    weight: number
}

export type Grouped = {
    no: number,
    endNo: number,
    expanded: boolean,
    key: string,
    value: string,
    parent: Grouped | null,
    children: (Grouped | SplunkLogRecord)[],
    totalNum: number,
    earliest: number,
    latiest: number,
    earliestDate: Date,
    latiestDate: Date,
    duration: number, //ms
    commonAttrs: CommonAttribute[],
    commonAttrsForDisplay: CommonAttribute[],
    level: number,
    highlight: string,
    hasError: boolean,
    hasWarning: boolean,
    commonKeys: CommonKey[],

}

type ViewerAdded = {
    //gcp case, parse as json
    _rawAsJson: any,

    refinedRaw: string,

    refinedRawForDisplay: string,

    timestamp: number

}

//TODO a lot of them are stubhub special, clean them up
type SplunkNode_Result = {
    __: ViewerAdded,
    _time: string,

    app_pool: string,

    sourcetype: string,
    location: string,

    _raw: string,

    thread: string,
    app_name: string,
    priority: string,
    method: string,
}

const RESULT = "result"

export type SplunkLogRecord = {
    no: number,
    parent: Grouped,
    result: SplunkNode_Result
}

export function isGrouped(son: Grouped | SplunkLogRecord) {
    return 'children' in son;
}

const dummyDate = new Date(Date.now())

export class SplunkLogDealer {

    recordNoCounter: number = 0
    recordsOrderReversed: boolean = true

    customized: Customized | null = null

    public setCustomized(customized: Customized) {
        if (this.customized) {
            //if already exists
            this.customized = customized

            if (this.allLines) {
                this.loadRecords()
            }

        } else {
            this.customized = customized
        }
    }

    private combineCommon(grouped: Grouped) {

        // if (!Date.parse(grouped.latiest) || !Date.parse(grouped.earliest)) {
        //     console.log("xxx", grouped)
        // }
        grouped.latiestDate = new Date(grouped.latiest)
        grouped.earliestDate = new Date(grouped.earliest)

        grouped.duration = grouped.latiestDate.getTime() - grouped.earliestDate.getTime()

        grouped.endNo = grouped.children.slice(-1)[0].no

        if (!grouped.children || grouped.children.length < 2) {

            //for single record, no need combine, just replace from its parent
            if (grouped.parent) {
                this.replaceCommonAttrs(grouped, grouped.parent.commonAttrs);
            }
            this.extractHighlight(grouped);
            return
        }

        this.originalCommonKeys.forEach(commonKey => {
            this.combineForOneAttr(grouped, commonKey.key, commonKey.key.startsWith("dye"));
        })

        // try {
        //     console.log("xxx", grouped.earliestDate.toISOString(), grouped.latiestDate.toISOString())
        // } catch (e) {
        //     console.log(e)
        // }

        let commonTime = sharedStart(grouped.earliestDate.toISOString(), grouped.latiestDate.toISOString())

        if (commonTime.lastIndexOf(',') > 0) {
            commonTime = commonTime.slice(0, commonTime.lastIndexOf(','))
        } else if (commonTime.lastIndexOf(':') > 0) {
            commonTime = commonTime.slice(0, commonTime.lastIndexOf(':'))
        } else if (commonTime.lastIndexOf(' ') > 0) {
            commonTime = commonTime.slice(0, commonTime.lastIndexOf(' '))
        } else if (commonTime.lastIndexOf('-') > 0) {
            commonTime = commonTime.slice(0, commonTime.lastIndexOf('-'))
        }

        //TODO for SH
        commonTime = commonTime.replace("T", " ")

        grouped.commonAttrs.push({
            key: "time",
            value: commonTime,
            includeWithoutKeyWhenMatch: true
        })

        //go through every record, to replace those common attr
        this.replaceCommonAttrs(grouped, grouped.commonAttrs);

        //remove those common attrs shared in parent (but exclude root, as root didn't show)
        grouped.commonAttrsForDisplay = [...grouped.commonAttrs]
        if (grouped.parent) {
            grouped.parent.commonAttrs.forEach(e => {
                _.remove(grouped.commonAttrsForDisplay, (attr) =>
                    attr.key == e.key && attr.value == e.value || attr.key == grouped.key
                )
            })
        }

        this.extractHighlight(grouped);

    }

    private extractHighlight(grouped: Grouped) {
        grouped.highlight = "";
        let messages = "";

        (grouped.children as SplunkLogRecord[]).forEach(e => {

            if (e.result.priority == "WARN") {
                grouped.hasWarning = true
            }

            if (e.result.priority == "ERROR") {
                grouped.hasError = true
            }

            let method = jsonpath.value(e.result, "method");

            if (method && !grouped.highlight.includes(method)) {
                grouped.highlight += method + ",";
            }

            if (!method) {
                const message = jsonpath.value(e.result, "message");

                if (message) {
                    messages += message + ","
                }
            }

        });

        if (grouped.highlight.length == 0 && messages.length > 0) {
            grouped.highlight = messages
        }


    }

    private replaceCommonAttrs(grouped: Grouped, commonAttrs: CommonAttribute[]) {
        (grouped.children as SplunkLogRecord[]).forEach(e => {

            let original = e.result.__.refinedRaw

            commonAttrs.forEach(comm => {
                original = original.replace(comm.key + "=" + comm.value, "**")
                e.result.__.refinedRawForDisplay = original

                if (comm.includeWithoutKeyWhenMatch) {
                    original = original.replace(comm.value, "**");

                    e.result.__.refinedRawForDisplay = original

                }
            });
        });
    }

    private combineForOneAttr(grouped: Grouped, variableName: string, withoutKey: boolean = false) {
        let set = new Set<string>();

        (grouped.children as any[]).forEach(e => {
            const value = e.result[variableName];

            set.add(value);
        });

        if (set.size == 1) {
            const value = Array.from(set)[0];
            if (value) {
                grouped.commonAttrs.push({
                    key: variableName,
                    value,
                    includeWithoutKeyWhenMatch: withoutKey
                })
            }
        }
    }

    private groupBy(parent: Grouped | null, list: (SplunkLogRecord)[], key: string): Grouped[] {
        let result: Grouped[] = []

        let lastGrouped: Grouped | null = null

        let showKey = key.replace(RESULT + ".", "")

        list.forEach((e: SplunkLogRecord) => {
            let value = jsonpath.value(e, "$.." + key)

            if (!value) {
                value = "N/A"
            }

            const time = e.result.__.timestamp;

            e.result.__.refinedRaw = e.result.__.refinedRaw.replace(showKey + "=" + value, "**")

            if (lastGrouped && lastGrouped.value == value) {
                //add to it
                lastGrouped.children.push(e)
                lastGrouped.totalNum++
                if (time > lastGrouped.latiest) {
                    lastGrouped.latiest = time
                }
                if (time < lastGrouped.earliest) {
                    lastGrouped.earliest = time
                }
            } else {

                //combine
                if (lastGrouped) {
                    this.combineCommon(lastGrouped)
                }

                //create new
                lastGrouped = {
                    no: e.no,
                    endNo: e.no,
                    expanded: false,
                    key: showKey,
                    value,
                    parent,
                    children: [e],
                    totalNum: 1,
                    earliest: time,
                    latiest: time,
                    earliestDate: dummyDate,
                    latiestDate: dummyDate,
                    duration: 0,
                    commonAttrs: [],
                    commonAttrsForDisplay: [],
                    level: parent ? parent.level + 1 : 0,
                    highlight: "",
                    hasError: false,
                    hasWarning: false,
                    commonKeys: [],
                }
                result.push(lastGrouped)
            }

        })

        if (lastGrouped) {
            this.combineCommon(lastGrouped)
        }

        return result;
    }

    sortRoot(reverseTimeOrder: boolean) {

        if (this.root) {
            this.sort((this.root as Grouped).children as Grouped[], reverseTimeOrder)
        }

    }

    private sort(list: Grouped[], reverseTimeOrder: boolean): Grouped[] {

        if (list.length > 1) {
            list.sort((a, b) => {
                const x =
                    a.earliest
                const y =
                    b.earliest

                let result = ((x < y) ? -1 : ((x > y) ? 1 : 0));

                if (reverseTimeOrder) {
                    result *= -1
                }

                return result;

            })
        }

        list.forEach(e => {
            if (e.children && e.children.length > 0) {
                if (isGrouped(e.children[0])) {
                    e.children = this.sort(e.children as Grouped[], reverseTimeOrder)
                } else {
                    e.children = this.sortRecord(e.children as SplunkLogRecord[], reverseTimeOrder)
                }
            }
        })

        return list

    }


    private sortRecord(list: SplunkLogRecord[], reverseTimeOrder: boolean): SplunkLogRecord[] {

        if (list.length < 2) {
            return list
        }

        list.sort((a, b) => {
            // if (!a.result || !b.result) {
            //   console.log("xxx", a, b)
            // } 
            const x = a.no
            const y = b.no

            let result = (x < y) ? -1 : ((x > y) ? 1 : 0);

            if (reverseTimeOrder !== this.recordsOrderReversed) {
                result *= -1
            }

            return result;

        })

        return list
    }


    private root: Grouped | null = null
    private records: SplunkLogRecord[] | null = null

    async refresh(foldingRule: string, filterExpression: string, timeReverseSwitch: boolean, successCallback: any) {

        //console.log("enter refresh", 0)

        if (!this.records) {
            console.log("return no records", 0)
            return
        }

        let root: Grouped = this.init(this.fileName, foldingRule, filterExpression);

        if (timeReverseSwitch != this.recordsOrderReversed) {
            this.sortRoot(timeReverseSwitch)
        }

        //console.log("before callback", 1)
        successCallback(root)
        //console.log("after callback", 1)
    }

    private fileName: string = "ROOT"

    private allLines: string[] = []

    loadLines(fileName: string, content: string, foldingRule: string | null, filterExpression: string | null) {
        this.fileName = fileName

        this.allLines = content!.split(/\r\n|\n/);

        // Reading line by line
        this.loadRecords()

        let root: Grouped = this.init(fileName, foldingRule, filterExpression);

        return root


    }

    loadRecords() {

        console.log("load records")

        const context = {
            jp: jsonpath,
            _: _,
        };

        const list: SplunkLogRecord[] = []

        this.allLines.forEach((line) => {
            //console.log(line!);

            if (line && line.trim().length > 0) {

                var record: SplunkLogRecord = JSON.parse(line);

                //put __ ahead for better view
                record.result = Object.assign(
                    {
                        "__": {
                            refinedRaw: record.result._raw,
                            _rawAsJson: null
                        }
                    }, record.result);

                record.no = ++this.recordNoCounter;

                let customized = this.customized;
                // (function () {
                //     const jp = jsonpath

                let goodLog = customized ? customized.postHandleRecord.call(context, record) : true;

                if (goodLog) {
                    const time = Date.parse(record.result._time);
                    record.result.__.timestamp = time;

                    list.push(record);
                }
                // })();
            }
        }
        );

        this.originalCommonKeys = this.calculateCommonKeys(list);

        this.records = list;

    }

    load(localFile: File, foldingRule: string | null, filterExpression: string | null, successCallback: (root: Grouped) => void, failedCallback: any) {
        const startTime = now();
        const reader = new FileReader();

        reader.onload = (e) => {

            try {
                const content: string = e!.target!.result! as string;

                const root = this.loadLines(localFile.name, content, foldingRule, filterExpression);
                console.log("taking time", (now() - startTime))
                console.log("with folding rule:", this.foldingRule)
                console.log("with filter:", this.filterExpression)
                successCallback(root)

            } catch (e) {
                console.log("onload error", e)
                failedCallback(e)
            }

        };

        reader.onerror = (e) => {
            console.log("onerror", e)
            failedCallback(e!.target!.error!.name)
        };

        //  console.log("xxx", localFile)

        reader.readAsText(localFile);
    }

    private originalCommonKeys: CommonKey[] = []

    private foldingRule: string | null = null



    getFoldingRule(): string {
        return this.foldingRule ? this.foldingRule : ""
    }

    private filterExpression: string | null = null

    getFilterExpression(): string {
        return this.filterExpression ? this.filterExpression : ""
    }


    private init(rootName: string, foldingRule: string | null, filterExpression: string | null) {

        console.log("init", foldingRule, filterExpression)

        this.foldingRule = foldingRule
        this.filterExpression = filterExpression

        let list: SplunkLogRecord[] = []

        this.records?.forEach(record => {
            if (filterExpression && filterExpression.length > 0) {
                try {
                    //console.log("eval", filterExpression, "on", record)
                    const evalResult = evalInContext(record.result, filterExpression);
                    if (evalResult) {
                        list.push(record)
                    }
                } catch (e) {
                    console.log("eval", filterExpression, "on", record, e)
                }
            } else {
                list.push(record)
            }
        })

        this.root = {
            no: 0,
            endNo: 0,
            expanded: true,
            key: "",
            value: rootName,
            parent: null,
            children: list,
            totalNum: 0,
            earliest: Date.now(),
            latiest: 0,
            earliestDate: dummyDate,
            latiestDate: dummyDate,
            duration: 0,
            commonAttrs: [],
            commonAttrsForDisplay: [],
            level: 0,
            highlight: "",
            hasError: false,
            hasWarning: false,
            commonKeys: [],
        };

        let root: Grouped = this.root as Grouped;

        this.recordNoCounter = 0;

        list.forEach((obj, index) => {

            //console.log(line!);

            const time = obj.result.__.timestamp

            root.totalNum++;

            if (time > root.latiest) {
                root.latiest = time;
            }
            if (time < root.earliest) {
                root.earliest = time;
            }
        }
        )


        root.commonKeys = this.originalCommonKeys

        if (this.foldingRule == null || this.foldingRule.length == 0) {

            if (this.customized?.defaultFoldingRule && this.customized?.defaultFoldingRule.length > 0) {
                this.foldingRule = this.customized?.defaultFoldingRule.join(",")
            } else {
                this.foldingRule = this.autoPopulateFoldingRule(root.commonKeys);
            }

            //console.log("xxx", "foldingRule", this.foldingRule);

        }

        //console.log("xxx", root.commonKeys)

        //do this before group
        this.combineCommon(root);

        this.folding(root, this.foldingRule!);

        if (false) {
            //TODO expose this as OPTION ?
            this.mergeSingleChild(root)
        }

        //console.log("xxx", root);

        if (list.length > 1) {
            this.recordsOrderReversed = list[0].result.__.timestamp > list[list.length - 1].result.__.timestamp;
        }

        //as sometime, the record time is not aligned
        this.sortRoot(this.recordsOrderReversed)

        return root;
    }

    private autoPopulateFoldingRule(commonKeys: CommonKey[]): string | null {

        //console.log("xxx", "commonKeys", commonKeys)

        for (let index = 0; index < commonKeys.length - 1; index++) {
            const key = commonKeys[index]

            let foldingRule = commonKeys[index].key;

            loop: for (let i = index + 1; i < commonKeys.length; i++) {
                const anotherKey = commonKeys[i]
                if (!anotherKey.notRecommand) {

                    if (anotherKey.changeFrequency.changedTime < 2 * key.changeFrequency.changedTime) {
                        //too similar
                        continue loop
                    }

                    if (anotherKey.valuesVarientsCount < 2 * key.valuesVarientsCount) {
                        //too similar
                        continue loop
                    }

                    //TODO need a better way, check by range overlap
                    // for (let item of key.changeFrequency.changedSignature.values()) {
                    //     if (!anotherKey.changeFrequency.changedSignature.has(item)) {
                    //         break loop
                    //     }
                    // }

                    foldingRule += "," + anotherKey.key;
                    return foldingRule
                }
            }
        }

        return commonKeys[0].key
    }

    private calculateCommonKeys(list: SplunkLogRecord[]) {

        const commonKeys: CommonKey[] = []

        let commonKeyValuesCounter = new Map<string, Map<string, number>>();

        let commonKeyValueChangeFrequency = new Map<string, ChangeFrequency>();

        list.forEach((obj, index) => {

            for (var prop in obj.result) {
                // skip loop if the property is from prototype
                if (!obj.result.hasOwnProperty(prop))
                    continue;
                //ignore those splunk keys    
                if (prop.startsWith("_")) {
                    continue;
                }
                if (prop.startsWith("date_")) {
                    continue;
                }
                if (prop.startsWith("data.")) {
                    continue;
                }
                if (prop.startsWith("punct")) {
                    continue;
                }
                if (prop.startsWith("index")) {
                    continue;
                }
                if (prop.startsWith("source")) {
                    continue;
                }
                if (prop.startsWith("splunk_server")) {
                    continue;
                }
                if (prop.startsWith("timestart") || prop.startsWith("timeend")) {
                    continue;
                }

                const value = (obj.result as any)[prop];

                if (!value) {
                    continue;
                }

                //exclude those array type
                if (Array.isArray(value)) {
                    continue;
                }
                if (_.isNumber(value)) {
                    continue;
                }

                if (_.isString(value)) {
                    const stringValue = (value as string);
                    if (stringValue.length == 0) {
                        continue;
                    }

                    //exclude numbers
                    if (/^\d*$/.test(value)) {
                        continue;
                    }

                }

                let valueCounter = commonKeyValuesCounter.get(prop);
                if (valueCounter == null) {
                    valueCounter = new Map<string, number>();
                    commonKeyValuesCounter.set(prop, valueCounter);
                }

                const count = valueCounter.get(value);
                if (count == null) {
                    valueCounter.set(value, 1);
                } else {
                    valueCounter.set(value, count + 1);
                }

                let changeFrequency = commonKeyValueChangeFrequency.get(prop);

                if (changeFrequency == null) {
                    changeFrequency = {
                        lastValue: value,
                        changedTime: 0,
                        changedSignature: new Set()
                    };

                    commonKeyValueChangeFrequency.set(prop, changeFrequency);

                } else {
                    if (changeFrequency.lastValue != value) {
                        changeFrequency.changedTime++;
                        changeFrequency.lastValue = value;
                        changeFrequency.changedSignature.add(index);
                    }
                }
            }

        });

        //console.log("xxx", "commonKeyValuesCounter", commonKeyValuesCounter)
        //console.log("xxx", "commonKeyValueChangeFrequency", commonKeyValueChangeFrequency)
        type KeyAndSignature = {
            name: string;
            signature: string;
        };

        //group by signature
        const group = _.groupBy(Array.from(commonKeyValueChangeFrequency).map(v => {
            let a: KeyAndSignature = { name: v[0], signature: Array.from(v[1].changedSignature).join(' ') };
            return a;
        }), (e) => {
            return e.signature;
        });

        //console.log("xxx", "group", group)
        const equalKeyMap = new Map<string, string>();
        _.forOwn(group, (group: KeyAndSignature[]) => {

            if (group.length > 1) {
                group.forEach((ks: KeyAndSignature, index) => {
                    if (index > 0) {
                        equalKeyMap.set(ks.name, group[0].name);
                    }
                });
            }
        });

        //console.log("xxx", "equalKeyMap", equalKeyMap)
        commonKeyValuesCounter.forEach(

            (value, key) => {
                //console.log("xxx", key, value)
                // app_pool Map(2) {"xxx-pool" => 8, "yyy-pool" => 8}
                //sort them based on the count descend
                let valuesRateMap = new Map([...value.entries()].sort((a, b) => b[1] - a[1]));
                let valuesVarientsCount = valuesRateMap.size
                let totalNumForSameKey = 0;

                let topValuesCounter = 0;
                valuesRateMap.forEach((v, k) => {
                    totalNumForSameKey += v;
                    const rate = v / list.length;
                    //only put first 5 top values
                    if (++topValuesCounter < 5) {
                        valuesRateMap.set(k, rate);
                    } else {
                        valuesRateMap.delete(k);
                    }
                });

                const includeRate = totalNumForSameKey / list.length;

                let changeFrequency = commonKeyValueChangeFrequency.get(key);

                //no need add those key less appeared
                if (includeRate > 0.66) {

                    //indentify those keys with same change
                    // changeFrequency!.changedSignature
                    let equalWithKey = equalKeyMap.get(key) || "";

                    commonKeys.push({
                        key: key,
                        includeRate: includeRate,
                        valuesRate: valuesRateMap,
                        valuesVarientsCount: valuesVarientsCount,
                        changeFrequency: changeFrequency!,
                        equalWithKey: equalWithKey,
                        //  for key 100% appear and with only one value, not recommand
                        //for those key had same effect, not recommand
                        notRecommand: (includeRate == 1 && valuesVarientsCount == 1) || equalWithKey.length > 0
                            || ['priority', 'thread', 'dye', 'sh_role'].includes(key),
                        weight: -1,
                    });
                }


            }

        );

        commonKeys.sort((a, b) => {


            if (a.weight < 0) {
                a.weight = this.calculateWeigth(a);
            }

            if (b.weight < 0) {
                b.weight = this.calculateWeigth(b);
            }

            return a.weight - b.weight;
        });

        return commonKeys
    }

    // the less weight the better
    private calculateWeigth(a: CommonKey) {
        //includeRate: 0.80, the bigger the better
        //changedTime: 23, the smaller the better
        //valuesRate.length: 4, the smaller the better

        let aWeight = (a.changeFrequency.changedTime + a.valuesVarientsCount) * (2 - a.includeRate);
        if (a.notRecommand) {
            aWeight = 10000;
        }

        return aWeight;
    }

    private mergeSingleChild(grouped: Grouped) {
        //if only one child, then merge this with its child
        if (grouped.children.length == 1) {
            let son = grouped.children[0]
            if (isGrouped(son)) {
                const sonGrouped = (son as Grouped);
                grouped.key += ", " + sonGrouped.key
                grouped.value += ", " + sonGrouped.value
                grouped.children = sonGrouped.children

                //continnue for it
                this.mergeSingleChild(grouped)

            }
        } else {
            grouped.children.forEach((e) => {
                if (isGrouped(e)) {
                    this.mergeSingleChild(e as Grouped);
                }
            })
        }
    }


    private folding(root: Grouped, foldingRule: string) {
        // const appPoolList = this.groupBy(root, list, "result.app_pool");

        // appPoolList.forEach(e => {
        //     e.children = this.groupBy(e, e.children as SplunkLogRecord[], "result.location");
        // });

        // return appPoolList;

        const rules = foldingRule.split(",");

        let listTOBeScanned = [root]

        rules.forEach((rule) => {
            if (rule.length == 0) {
                return
            }
            let newListTOBeScanned: Grouped[] = []
            listTOBeScanned.forEach((node) => {
                const children = this.groupBy(node, node.children as SplunkLogRecord[], RESULT + "." + rule);
                node.children = children
                newListTOBeScanned.push(...children)
            })
            listTOBeScanned = newListTOBeScanned
        })

    }

    // return the array of record no
    search(conditionExpression: string): Array<number> {

        let result: Array<number> = []

        this.records?.forEach(record => {
            try {
                //console.log("eval", conditionExpression, "on", record)
                const evalResult = evalInContext(record.result, conditionExpression);
                if (evalResult) {
                    result.push(record.no)
                }
            } catch (e) {
                console.log("eval", conditionExpression, "on", record, e)
            }

        })

        return result
    }
}

export function convertAdvancedFilter(filterText: string) {
    if (filterText.includes("this.")) {
        return filterText;
    }
    return `this._raw.toLowerCase().includes('${filterText}'.toLowerCase())`;
}

export function convertSimpleFilter(filterText: string) {

    let result = filterText.slice(filterText.indexOf("(") + 2, filterText.lastIndexOf(")") - 1);

    if (result.includes("this.")) {
        return filterText
    }
    return result
}


