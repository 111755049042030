import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Fab from '@material-ui/core/Fab';

import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import RefreshIcon from '@material-ui/icons/Refresh';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState, useCallback, useRef } from 'react';
import ReactJson from 'react-json-view';
import './App.css';
import RecordGroup from './RecordGroup';
import { convertAdvancedFilter, convertSimpleFilter, Customized, Grouped, isGrouped, SplunkLogDealer, SplunkLogRecord } from './SplunkLogDealer';
import _, { update } from 'lodash';
import { useDropzone } from 'react-dropzone'
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import { ToastContainer, toast, ToastContent, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import jsonp from 'jsonp';

import firebase from "firebase/app";

import "firebase/analytics";
import { StyledDropzone } from './FileLoader';
import AutoComplete, { AutoCompleteOptionType, AutoCompletePropsType } from './AutoComplete';
import OutlinedDiv from './OutlinedDiv';
import ReactDOM from 'react-dom';
import { trimEllip } from './util';

import { Box, DialogContentText, DialogTitle } from '@material-ui/core';

import Editor from 'react-simple-code-editor';
import { highlight, languages, highlightElement } from 'prismjs';

// import 'prismjs/components/prism-clike';
// import 'prismjs/components/prism-javascript';
import "prismjs/themes/prism.css";

import jp from "jsonpath"


var firebaseConfig = {
  apiKey: "AIzaSyDun2-Ff0bpN4lVTTBV-kD_cWEitgp4ecc",
  authDomain: "splunk-log-assist.firebaseapp.com",
  databaseURL: "https://splunk-log-assist.firebaseio.com",
  projectId: "splunk-log-assist",
  storageBucket: "splunk-log-assist.appspot.com",
  messagingSenderId: "239714391825",
  appId: "1:239714391825:web:c7b26c1870cd28a5019eb0",
  measurementId: "G-L53LSRCS8R"
};

try {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
} catch (e) {
  console.log(e)
}

let stubhub = false

// jsonp("https://ipinfo.io", function (err: any, data: any) {
//   if (err) {
//     console.error(err.message);
//   } else {
//     console.log(data.ip);
//   }
// });

fetch("https://api.ipify.org?format=json")
  .then((response) => {
    //console.log(" response", response)
    if (!response.ok) {
      throw new Error('Network response was ' + response.statusText);
    }
    return response.json();
  })
  .then((obj: any) => {
    console.log("xxx", obj.ip)
    if ("34.94.219.83" == obj.ip) {
      stubhub = true
    }

  })
  .catch((error) => {
    console.error('There has been a problem with your fetch operation:', error);
  });

const logDealer = new SplunkLogDealer();


type ExcludeTag = Tag & {

}


var initDone = false

const storge_key_setting_code = "splunk-assist.settings.code"

const defaultCustomizedJS = `
{
  postHandleRecord: (record) => {
    const jp = this.jp //import jp from 'jsonpath'
    const _ = this._ //import _ from 'lodash'

    //
    // put your logic here
    //

    return true
  },

  defaultFoldingRule: ["app_pool", "location"],
  
  defaultSubFilters: [
    "this.location !== 'TestClass'",
  ]
}
`

function App() {

  const [root, setRoot] = useState<Grouped>();
  const [timeReverseSwitch, setTimeReverseSwitch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [foldingRule, setFoldingRule] = useState<string>("");

  const [selectedFoldingFieldOptions, setSelectedFoldingFieldOptions] = useState<AutoCompleteOptionType[]>([]);
  const [foldingFieldOptions, setFoldingFieldOptions] = useState<AutoCompleteOptionType[]>([]);

  const [advancedFilterOn, setAdvancedFilterOn] = useState<boolean>(false);
  const [filterExpression, setFilterExpression] = useState<string>("");

  const [excludeTags, setExcludeTags] = useState<ExcludeTag[]>([]);

  const [detailRecordViewOpen, setDetailRecordViewOpen] = useState<boolean>(false);
  const [detailRecord, setDetailRecord] = useState<SplunkLogRecord | null>(null);

  const [jumpFabOpen, setJumpFabOpen] = useState<boolean>(false);
  const [jumpCandidates, setJumpCandidates] = useState<Array<number>>([]);
  const [jumpCandidatesIndex, setJumpCandidatesIndex] = useState<number>(0);

  const updateExcludeTags = (newExcludeTags: ExcludeTag[]) => {

    console.log("newExcludeTags", newExcludeTags)

    setExcludeTags(newExcludeTags)

    updateFilter(filterExpression, newExcludeTags)
  }

  const excludeGroup = (grouped: Grouped) => {
    // if (!advancedFilterOn) {
    //   setAdvancedFilterOn(true)
    // }

    let addFilter = "this." + grouped.key + " !== '" + grouped.value + "'"

    const newExcludeTags = [...excludeTags, { id: addFilter, text: addFilter }];

    updateExcludeTags(newExcludeTags)

  }

  const controller = {
    "setDetailRecordViewOpen": setDetailRecordViewOpen,
    "setDetailRecord": setDetailRecord,
    "excludeGroup": excludeGroup,
  }

  const filterTextRef = React.createRef<HTMLInputElement>();

  const loadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let localFile = event!.target!.files![0]

    loadJson(localFile)

  }

  const loadJson = (localFile: File) => {

    //console.log("xxx", "excludeTags", excludeTags)
    //console.log("xxx", "filterExpression", filterExpression)

    const filter = getFilterExpression(filterExpression, excludeTags);

    //console.log("xxx", foldingRule, filter)

    logDealer.load(localFile, foldingRule, filter, (root: Grouped) => {
      setLoading(false)

      updateFoldingRuleOptionsAndValue(root, logDealer.getFoldingRule());
      updateRoot(root)
      setTimeReverseSwitch(logDealer.recordsOrderReversed)
    }, (error: string) => {
      alert(error);
      setLoading(false)
    }
    )

    setLoading(true)
  }

  const loadSampleLog = () => {
    setLoading(true)

    fetch("/splunk.demo.logs.simple.json")
      .then((response) => {
        //console.log(" response", response)
        if (!response.ok) {
          throw new Error('Network response was ' + response.statusText);
        }
        return response.text();
      })
      .then((text: string) => {
        setLoading(false)

        const newRoot = logDealer.loadLines("sample log", text, foldingRule, "");
        setTimeReverseSwitch(logDealer.recordsOrderReversed)
        updateFoldingRuleOptionsAndValue(newRoot, logDealer.getFoldingRule());

        updateRoot(newRoot)

      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }

  React.useEffect(() => {

    if (root == null) {
      _.delay(loadSampleLog, 500)
    }

  }, []);

  React.useEffect(() => {
    //console.log("xxx", filterTextRef, filterTextRef.current)
    if (initDone) {
      return
    }

    if (filterTextRef.current != null) {
      //only focus the first time page load
      filterTextRef.current?.focus();
      initDone = true

      let savedCode = window.localStorage.getItem(storge_key_setting_code)

      if (savedCode) {
        notify(toast.info, "Welcome back, loading saved settings from local storage")
        if (updateCode(savedCode)) {
          setCodeForSettings(savedCode)
        } else {
          notify(toast.error, "failed to load saved settings, check in console")
          console.log("saved settings with problem: [" + savedCode + "")
          savedCode = null
          window.localStorage.removeItem(storge_key_setting_code)
        }
      } else {
        updateCode(codeForSettings)
      }

      if (stubhub) {

        if (!savedCode) {
          notify(toast.info, "Welcome Stubhuber, loading stubhub settings")

          loadStubhubSetting();
        }
      }

    }

  }, [filterTextRef]);

  let rootRecordGroupRef = useRef<any>({});
  let rootDivRef = useRef<any>({});
  let autoCompleteRef = useRef<any>({});

  const dragState = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      loadJson(acceptedFiles[0])
    },
    accept: '.json',

    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  })


  const handleDelete = (i: number) => {
    updateExcludeTags(excludeTags.filter((tag: Tag, index: number) => index !== i))
  }

  const handleAddition = (tag: ExcludeTag) => {

    const subs = tag.id.split("&&");

    if (subs.length == 1) {
      updateExcludeTags([...excludeTags, tag])
    } else {
      const newTags = [...excludeTags]
      subs.forEach(s => {
        s = s.trim()
        if (s.length > 0) {
          newTags.push({ id: s, text: s })
        }
      })
      updateExcludeTags(newTags)
    }
  }

  const notify = (
    toastFunc: (content: ToastContent, options?: ToastOptions<{}> | undefined) => React.ReactText,
    msg: ToastContent
  ) => {
    toastFunc(msg, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  const updateRoot = (root: Grouped) => {

    if (root.totalNum == 0) {
      notify(toast.warn, 'no records, check your filters!');
    }

    setJumpFabOpen(false)

    setRoot(root)
  }

  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
  const [savedCode, setSavedCode] = React.useState(defaultCustomizedJS);

  const handleSettingsDialogClickOpen = () => {
    setSettingsDialogOpen(true);

    setSavedCode(codeForSettings)
  };

  const handleSettingsDialogClose = () => {
    setSettingsDialogOpen(false);

    setCodeForSettings(savedCode)

  };


  const [codeForSettings, setCodeForSettings] = React.useState(defaultCustomizedJS);


  return (
    <div >
      <div style={{
        float: "left", position: "absolute", margin: "10px",
      }}>
        {
          stubhub &&
          <img src={"stubhub-logo.png"} style={{ width: "150px", height: "60px", background: "transparent" }}></img>

        }
      </div>
      <div style={{
        textAlign: "center", padding: "5px"

      }} >

        <h1 style={{ textAlign: "center" }} >Splunk Log Viewer

          <Tooltip
            title={
              <React.Fragment>
                <h3>For non profit usage only! Copyright of Splunk belong to Splunk Inc! <br /> Use it when you have more than 30 events in your splunk query, to get a fluent experience, if you have more than 1000 records, better refine the query to reduce the total number of events</h3>

              </React.Fragment>
            }
          >
            <HelpIcon />
          </Tooltip>
        </h1>
        <h4 style={{ textAlign: "center" }} >Easy tracing with auto folding and filters</h4>


        <StyledDropzone state={dragState}>

          <Container maxWidth="sm">

            <Tooltip
              title={"Settings, include customize how records post handled"}>
              <IconButton onClick={handleSettingsDialogClickOpen}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>

            <Button variant="contained" size="large" component="label" color="primary" startIcon={<OpenInBrowserIcon />}>
              Load Splunk Exported Log in Json
              <input type="file" name="file" accept=".json"
                style={{ display: "none" }}
                onChange={
                  loadFile
                }
              />
            </Button>

            <Tooltip
              title={
                <React.Fragment>
                  <h2>{"Local parse only, the tool NEVER upload user files"}</h2>
                  <h2>{"Export file from splunk by"}</h2>
                  <img src={"splunk_export_1.png"} style={{ width: "300px", height: "180px" }}></img>
                  <img src={"splunk_export_2.png"} style={{ width: "300px", height: "180px" }}></img>
                </React.Fragment>
              }
            >
              <IconButton >
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Container>
        </StyledDropzone>



        <br />

        <Dialog open={settingsDialogOpen} onClose={handleSettingsDialogClose}
          fullScreen
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <React.Fragment>
                Include customize how the log records post handled
                <br />
                System variables include:
                <br />
                import _ from 'lodash'
                <br />
                import jp from 'jsonpath'
              </React.Fragment>
            </DialogContentText>

            <Editor
              value={codeForSettings}
              onValueChange={code => setCodeForSettings(code)}
              highlight={code => highlight(code, languages.js, 'js')}
              padding={10}

              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                width: "100%"
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={
              handleSettingsDialogClose
            }>Cancel</Button>
            <Button variant="contained" onClick={() => {

              if (updateCode(codeForSettings)) {

                if (codeForSettings != defaultCustomizedJS) {
                  window.localStorage.setItem(storge_key_setting_code, codeForSettings)
                }

                setSettingsDialogOpen(false);

                notify(toast.info, "refreshing data")
                updateFilter(filterExpression, excludeTags)

              }

            }}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>



      {root && <div style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px"
      }}>
        <div >

          <OutlinedDiv label="Folding Rule" >

            <AutoComplete ref={autoCompleteRef}
              options={foldingFieldOptions}
              selectedOptions={selectedFoldingFieldOptions}
              onChange={
                (foldingFieldOptions) => {

                  setSelectedFoldingFieldOptions(foldingFieldOptions)

                  let theFolderingRule = ""
                  foldingFieldOptions.forEach(it => {
                    theFolderingRule += it.value + ","
                  })

                  setFoldingRule(theFolderingRule)

                  setLoading(true)

                  logDealer.refresh(theFolderingRule, filterExpression, timeReverseSwitch, (root: Grouped) => {

                    updateRoot(root)

                    setLoading(false)
                  })
                }
              }

            ></AutoComplete>
          </OutlinedDiv>

        </div>

        <div style={{ maxWidth: "65%" }}>

          <OutlinedDiv label="Records Filter">
            <Tooltip
              title={
                <React.Fragment>
                  <big>{`filter with JS expression, example: ${convertAdvancedFilter('keyword')}`}</big>
                </React.Fragment>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={advancedFilterOn}
                    onChange={(event) => {
                      const advancedOn = event.target.checked;
                      setAdvancedFilterOn(advancedOn);

                      let oldValue = filterTextRef.current!.value;

                      if (!oldValue) {
                        oldValue = ""
                      }

                      if (advancedOn) {
                        filterTextRef.current!.value = convertAdvancedFilter(oldValue)
                      } else {
                        //TODO 
                        filterTextRef.current!.value = convertSimpleFilter(oldValue)
                      }
                    }}
                  />
                }
                label="Advanced?"
                labelPlacement="bottom"
              />

            </Tooltip>

            <TextField variant="outlined"
              label={advancedFilterOn ? "condition expression" : "condition text"}
              style={{ width: "600px" }}
              defaultValue={""}

              //to prevent https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
              InputLabelProps={{ shrink: true }}
              inputRef={filterTextRef}

            // onChange={(event) => {
            //   const filterText = (event.target as HTMLTextAreaElement).value;
            //   setFilterExpression(`this._raw.includes("${filterText}")`)
            // }} 
            />


            <Button size="large" color="primary" variant="contained" style={{ margin: "5px" }}
              onClick={() => {
                let filterText = filterTextRef.current!.value
                if (!advancedFilterOn) {
                  filterText = convertAdvancedFilter(filterText);
                }

                setFilterExpression(filterText)

                updateFilter(filterText, excludeTags);
              }}> Filter
            </Button>

            <Button size="large" color="primary" variant="contained" style={{ margin: "5px" }}
              onClick={() => {
                let filterText = filterTextRef.current!.value
                if (!advancedFilterOn) {
                  filterText = convertAdvancedFilter(filterText);
                }

                let jumpCandidates = logDealer.search(filterText)

                if (jumpCandidates.length > 0) {

                  if (timeReverseSwitch != logDealer.recordsOrderReversed) {
                    jumpCandidates = jumpCandidates.reverse()
                  }

                  setJumpFabOpen(true)
                  setJumpCandidates(jumpCandidates)
                  setJumpCandidatesIndex(0)

                  rootRecordGroupRef.current.jumpTo(jumpCandidates[0])

                } else {
                  notify(toast.warn, "not found")
                  setJumpFabOpen(false)

                }

              }}> Find
            </Button>

            <div style={{ maxWidth: "100%" }}
            >
              <ReactTags
                tags={excludeTags}
                placeholder={"Sub filters -> "}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="top"
                allowDragDrop={false}
                readOnly={false}
              />
            </div>

          </OutlinedDiv>
        </div>

        <div>
          <FormControlLabel
            control={
              <Switch
                checked={timeReverseSwitch}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTimeReverseSwitch(event.target.checked)
                  logDealer.sortRoot(event.target.checked)
                }}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={`Reverse Time (${timeReverseSwitch ? "on: latest on top" : "off: oldest on top"})`}
            labelPlacement="bottom"
          />
        </div>

      </div>}


      <div style={{
        display: "flex",
        justifyContent: "center"
      }}>

        {loading && <CircularProgress disableShrink size={50} />}

        {/* {loading && <LinearProgress /> } */}

      </div>

      {!loading && detailRecord &&
        <div style={{
          width: "100%",
          height: "100%"
        }}>
          <Dialog
            open={detailRecordViewOpen}
            onClose={() => {
              setDetailRecordViewOpen(false);
            }}
            fullWidth={true}
            maxWidth={'md'}

          >
            {/* <DialogTitle id="alert-dialog-title">{"Record Json"}</DialogTitle> */}

            <DialogContent>
              <div style={{ width: "100%", height: "100%" }}>
                <ReactJson src={detailRecord.result} displayDataTypes={false} name="result" />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setDetailRecordViewOpen(false);
              }} color="primary">
                Close
              </Button>

            </DialogActions>
          </Dialog>
        </div>
      }


      <div ref={rootDivRef}>
        {!loading && root && (
          <RecordGroup key={root.no} data={root} expanded={root.expanded} controller={controller} ref={rootRecordGroupRef} />

        )}

      </div>


      <Fab color="primary" className="fab" onClick={() => {
        rootDivRef.current.scrollIntoView()
      }}>
        <PublishRoundedIcon />

      </Fab>


      {jumpFabOpen &&
        <div className="fab_right">

          <IconButton color="primary" onClick={() => {
            filterTextRef.current?.focus()
          }}>
            <EditIcon />

          </IconButton>

          <IconButton color="primary" size="medium" onClick={() => {
            let newIndex = jumpCandidatesIndex - 1
            if (newIndex < 0) {
              newIndex = jumpCandidates.length - 1
            }
            setJumpCandidatesIndex(newIndex)
            rootRecordGroupRef.current.jumpTo(jumpCandidates[newIndex])

          }}>
            <NavigateBeforeIcon />

          </IconButton>

          {/*TODO show ? ${filterTextRef.current.value}  */}
          {`${jumpCandidatesIndex + 1} / ${jumpCandidates.length}`}

          <IconButton color="primary" onClick={() => {
            let newIndex = jumpCandidatesIndex + 1
            if (newIndex > jumpCandidates.length - 1) {
              newIndex = 0
            }
            setJumpCandidatesIndex(newIndex)
            rootRecordGroupRef.current.jumpTo(jumpCandidates[newIndex])
          }}>
            <NavigateNextIcon />
          </IconButton>

          <IconButton color="primary" onClick={() => {
            setJumpFabOpen(false)
          }}>
            <CancelIcon />
          </IconButton>
        </div>

      }


      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />


    </div >
  );

  function loadStubhubSetting() {
    fetch("/customized/Stubhub.jsx")
      .then((response) => {
        //console.log(" response", response)
        if (!response.ok) {
          throw new Error('Network response was ' + response.statusText);
        }
        return response.text();
      })
      .then((text: string) => {
        const code = text.replace("xxx = ", "");
        setCodeForSettings(code);
        updateCode(code);
      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }

  function updateCode(code: string): boolean {

    //console.log("xxx", code);

    const context = {
      jp: jp,
      _: _,
    };

    try {
      const customizedObj = function (str: string) {
        return eval(str);
      }.call(context, `(${code})`) as Customized;

      //console.log("xxx", customizedObj);

      //TODO validate it

      logDealer.setCustomized(customizedObj);

      let theExcludeTags = customizedObj.defaultSubFilters.map(v => { return { id: v, text: v }; })
      //console.log("theExcludeTags", theExcludeTags)
      setExcludeTags(theExcludeTags)

    } catch (e: any) {
      var exmsg = "error while try to eval it: ";
      if (e.message) {
        exmsg += e.message;
      }
      if (e.stack) {
        //   exmsg += ' | stack: ' + e.stack;
        console.log("error", e)
      }

      notify(toast.warn, exmsg)

      return false
    }

    return true

  }

  function updateFoldingRuleOptionsAndValue(root: Grouped, foldingRule: String) {
    const newSelectedFoldingFieldOptions = selectedFoldingFieldOptions;

    newSelectedFoldingFieldOptions.length = 0;

    const foldingFieldOptions = root?.commonKeys.map(
      value => {
        let desc = "";
        // if (value.notRecommand) {
        //   desc += "Not Recommand! "
        // }
        const topValues = Array.from(value.valuesRate).map((it, index) => index < 4 ? (trimEllip(it[0], 12) + "(" + Math.round(it[1] * 100) + "%) ") : "");

        if (value.includeRate == 1 && value.valuesRate.size == 1) {
          desc += `single value [${value.valuesRate.keys().next().value}]`;
        } else if (value.equalWithKey.length > 0) {
          desc += `equivalent of ${value.equalWithKey}, top values [ ${topValues}]`;
        } else {
          desc += `${Math.round(value.includeRate * 100)}% has it, value changed times:${Math.round(value.changeFrequency.changedTime)}, top values [ ${topValues}]`;
        }
        const option = {
          name: value.key,
          desc: desc,
          value: value.key
        };

        //populate the selected options
        if (foldingRule.split(",").includes(value.key)) {
          newSelectedFoldingFieldOptions.push(option);
        }

        return option;
      }
    );

    setFoldingFieldOptions(foldingFieldOptions ? foldingFieldOptions : []);
    //console.log("xxx", "newSelectedFoldingFieldOptions", newSelectedFoldingFieldOptions);
    setSelectedFoldingFieldOptions(newSelectedFoldingFieldOptions);
  }

  function updateFilter(filterText: string, newExcludeTags: ExcludeTag[]) {

    //console.log("updateFilter", filterText)

    var newFilterExpression = getFilterExpression(filterText, newExcludeTags);

    //console.log("newFilterExpression", newFilterExpression)

    setLoading(true);
    logDealer.refresh(foldingRule, newFilterExpression, timeReverseSwitch, (root: Grouped) => {
      //console.log("done refresh")

      updateRoot(root);

      setLoading(false);
    });

    //console.log("after refresh")

    setTimeout(() => {
      //why we need this? a bug, the load is not hidden
      setLoading(false);
    }, 5000);
  }

  function getFilterExpression(filterText: string, newExcludeTags: Tag[]) {
    var newFilterExpression = "(" + (!!filterText ? filterText : "true") + ")";

    newExcludeTags.forEach((tag: Tag) => {
      newFilterExpression += ` && ${tag.id}`;
    });
    return newFilterExpression;
  }
}

export default App;


