import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'

//https://stackoverflow.com/questions/55516006/customizing-the-expansionpanel-in-material-ui-and-overwriting-the-styles
export const ExpansionPanel = withStyles((theme) => ({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        // '&:before': {
        //     display: 'none',
        // },
        // '&$expanded': {
        //     margin: 'auto',
        // },
        padding: theme.spacing(0),

    },
    expanded: {},
}))(Accordion);

export const ExpansionPanelSummary = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        // minHeight: 56,
        // '&$expanded': {
        //     minHeight: 56,
        // },
        minHeight: 7 * 4,
        "&$expanded": {
          minHeight: 7 * 4
        }
    },
    content: {
        margin: "4px 0",
        '&$expanded': {
            margin: '4px 0',
        },
    },
    expandIcon: {
        padding: 3
    },
    expanded: {},
}))(AccordionSummary);

export const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(AccordionDetails);

