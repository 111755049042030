import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { SplunkLogRecord } from "./SplunkLogDealer";
import ShowMoreText from 'react-show-more-text';


type RecordType = {
  data: SplunkLogRecord,
  controller: any
}

const Record: React.FunctionComponent<RecordType> = props => {
  const record: SplunkLogRecord = props.data;

  //console.log("yyy", props.data)

  return (
    <React.Fragment>
      <IconButton size="small" style={{ paddingLeft: "15px" }}
        onClick={() => {
          props.controller.setDetailRecord(record)
          props.controller.setDetailRecordViewOpen(true)
        }} >
        <VisibilityIcon />
      </IconButton>

      {showRaw(record.result.__.refinedRawForDisplay)}

    </React.Fragment>

  )
}


export function showRaw(raw: string): React.ReactNode {

  //a toggle here to turn off the syntax highlight
  if (false) {
    return <span style={{ display: "inline", maxWidth: "100%", wordWrap: "break-word" }}>{raw}</span>
  }

  const regex = /([.\w\_\-]+)(=)([^\s{}\"]+|\".*?\"|{.*})/gi;
  let parts: any[] = raw.split(regex);
  //console.log(parts)
  //["**:52:33,003 [] ", "priority", "=", "INFO", "   ", "thread", "=", "ThreadPoolTaskExecutor-7", " ** ", "line", "=", "58",
  let index = 1
  while (index < parts.length) {
    
    if (parts[index] == "=") {
      // there is some chance, this doesn't work
      parts[index - 1] = <span key={index - 1} className="Record-raw-key">{parts[index - 1]}</span>
      parts[index + 1] = <span key={index + 1} className="Record-raw-value">{parts[index + 1]}</span>
      index += 2
    } else {

      // better handle java exception track
      if ((parts[index].match(/\s+at\s+[\w\d\.\$\:]+(\([\w\d\:\.]*?\))?\s/gi) || []).length >= 5) {
        parts[index] =
          <ShowMoreText
            lines={5}
            more='Show more'
            less='Show less'
            expanded={false}
            key={"more_" + index}
          >
            {/* TODO handle ... like at org.apache.cxf.jaxrs.client.WebClient.doChainedInvocation(WebClient.java:800) ... 87 common frames omitted Caused by: org.apache.cxf.interceptor.Fault: Could not send Message. */}
            {/* TODO can show only if expanded = true */}
            {parts[index].split(/\sat /gi).map((s: string, theI: number) => <React.Fragment key={index + "_" + theI}>at {s}<br /></React.Fragment>)}
          </ShowMoreText>
      }

      index++

    }
  }

  return <div style={{ display: "inline", maxWidth: "100%", wordWrap: "break-word" }}>{parts}</div>;
}

export default Record